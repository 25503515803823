import { gsap } from "gsap";

// Function to create the neon-density effect
export function neonDensityEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#ff0000', // Neon red color
            willChange: 'text-shadow'
        });

        // Timeline for the density animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "none",
            duration: 3
        });

        tl.to(element, {
            textShadow: "0 0 15px rgba(255, 0, 0, 1), 0 0 30px rgba(255, 0, 0, 0.8), 0 0 45px rgba(255, 0, 0, 0.6)"
        });
    });
}
