import { gsap } from "gsap";

// Function to create the neon-fade-in-out effect
export function neonFadeInOutEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'opacity, text-shadow',
            opacity: 0.7, // Start with reduced opacity
            textShadow: "none" // Start with no text shadow
        });

        // Define the animation timeline
        const tl = gsap.timeline({
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "power1.inOut", // Smooth easing for the fade effect
            duration: 3 // Total duration of one fade cycle
        });

        // Define the keyframes for the fade in and out animation
        tl.to(element, {
            opacity: 1, // Fade in to full opacity
            textShadow: "0 0 10px rgba(255, 165, 0, 0.9), 0 0 20px rgba(255, 165, 0, 0.7), 0 0 30px rgba(255, 165, 0, 0.5)" // Bright neon glow
        })
        .to(element, {
            opacity: 0.7, // Fade out to reduced opacity
            textShadow: "none" // Dim the glow
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                opacity: 1, // Brighten on hover
                textShadow: "0 0 10px rgba(255, 165, 0, 0.9), 0 0 20px rgba(255, 165, 0, 0.7), 0 0 30px rgba(255, 165, 0, 0.5)", // Intensify glow on hover
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                opacity: 0.7, // Restore fade-out opacity after hover
                textShadow: "none", // Restore dimmed glow after hover
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// neonFadeInOutEffect('.neon-fade-in-out');
