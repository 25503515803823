import { gsap } from "gsap";

// Function to create the neon-glow effect
export function neonGlowEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#ffff33', // Neon yellow color
            willChange: 'text-shadow'
        });

        // Timeline for the glow animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "none",
            duration: 2
        });

        tl.to(element, {
            textShadow: "0 0 10px rgba(255, 255, 51, 1), 0 0 20px rgba(255, 255, 51, 0.8), 0 0 30px rgba(255, 255, 51, 0.6)"
        });
    });
}
