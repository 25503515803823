import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu pulsowania
const pulsePalettes = {
    default: {
        baseColor: 'rgba(0, 255, 255, 0.7)',
        pulseColor: 'rgba(0, 255, 255, 1)'
    },
    custom: {
        baseColor: 'rgba(255, 0, 0, 0.7)',
        pulseColor: 'rgba(255, 0, 0, 1)'
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-pulse`
export function neonPulseEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = pulsePalettes[paletteName] || pulsePalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        gsap.set(element, {
            textShadow: `0 0 10px ${palette.baseColor}, 0 0 20px ${palette.baseColor}, 0 0 30px ${palette.baseColor}`,
            willChange: 'transform'
        });

        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 1.5, // Czas trwania animacji
        });

        tl.to(element, {
            textShadow: `
                0 0 20px ${palette.pulseColor},
                0 0 30px ${palette.pulseColor},
                0 0 40px ${palette.pulseColor}
            `
        });

        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: `
                    0 0 20px ${palette.pulseColor},
                    0 0 30px ${palette.pulseColor},
                    0 0 40px ${palette.pulseColor}
                `,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: `
                    0 0 10px ${palette.baseColor},
                    0 0 20px ${palette.baseColor},
                    0 0 30px ${palette.baseColor}
                `,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
