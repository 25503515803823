import { gsap } from "gsap";

// Function to create the neon-gradient effect
export function neonGradientEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Apply gradient color and initial setup
        gsap.set(element, {
            background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,0,255,1) 100%)',
            '-webkit-background-clip': 'text',
            color: 'transparent',
            textShadow: "0 0 5px rgba(255, 0, 0, 0.7), 0 0 10px rgba(0, 255, 0, 0.5), 0 0 15px rgba(0, 0, 255, 0.3)"
        });
    });
}
