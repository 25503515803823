import { gsap } from "gsap";

// Function to create the text-neon-green effect
export function textNeonGreenEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'text-shadow',
            color: '#39ff14', // Set neon green color
            textShadow: "0 0 5px rgba(57, 255, 20, 0.7), 0 0 10px rgba(57, 255, 20, 0.5), 0 0 20px rgba(57, 255, 20, 0.3), 0 0 40px rgba(57, 255, 20, 0.2)" // Initial neon glow
        });

        // Define the animation timeline
        const tl = gsap.timeline({
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "power1.inOut", // Smooth easing for the glow effect
            duration: 2.5 // Total duration of one glow cycle
        });

        // Define the keyframes for the neon glow effect
        tl.to(element, {
            textShadow: "0 0 15px rgba(57, 255, 20, 1), 0 0 30px rgba(57, 255, 20, 0.8), 0 0 45px rgba(57, 255, 20, 0.6), 0 0 60px rgba(57, 255, 20, 0.4)" // Stronger glow
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 15px rgba(57, 255, 20, 1), 0 0 30px rgba(57, 255, 20, 0.8), 0 0 45px rgba(57, 255, 20, 0.6)", // Intensify glow on hover
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 5px rgba(57, 255, 20, 0.7), 0 0 10px rgba(57, 255, 20, 0.5), 0 0 20px rgba(57, 255, 20, 0.3), 0 0 40px rgba(57, 255, 20, 0.2)", // Restore original glow after hover
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// textNeonGreenEffect('.text-neon-green');
