import { gsap } from "gsap";

// Function to create the natural-pulse effect
export function neonNaturalPulseEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'text-shadow',
            color: '#0099ff', // Neon blue color
            textShadow: "0 0 5px rgba(0, 153, 255, 0.8), 0 0 15px rgba(0, 153, 255, 0.6), 0 0 30px rgba(0, 153, 255, 0.4)" // Initial pulse shadow
        });

        // Define the animation timeline
        const tl = gsap.timeline({
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "power1.inOut", // Smooth easing for the pulse effect
            duration: 2.5 // Total duration of one pulse cycle
        });

        // Define the keyframes for the pulse animation
        tl.to(element, {
            textShadow: "0 0 10px rgba(0, 153, 255, 1), 0 0 20px rgba(0, 153, 255, 0.8), 0 0 40px rgba(0, 153, 255, 0.6)" // Stronger glow during the pulse
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 10px rgba(0, 153, 255, 1), 0 0 20px rgba(0, 153, 255, 0.8), 0 0 40px rgba(0, 153, 255, 0.6)", // Intensify glow on hover
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 5px rgba(0, 153, 255, 0.8), 0 0 15px rgba(0, 153, 255, 0.6), 0 0 30px rgba(0, 153, 255, 0.4)", // Restore original glow after hover
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// naturalPulseEffect('.natural-pulse');
