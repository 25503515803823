import { gsap } from "gsap";

// Function to create the neon-flicker effect
export function neonFlickerEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#ff3399', // Neon pink color
            willChange: 'text-shadow'
        });

        // Timeline for the flicker animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "none",
            duration: 1.5
        });

        tl.to(element, {
            textShadow: "0 0 10px rgba(255, 51, 153, 1), 0 0 20px rgba(255, 51, 153, 0.8), 0 0 40px rgba(255, 51, 153, 0.6)"
        })
        .to(element, {
            textShadow: "0 0 8px rgba(255, 51, 153, 0.7), 0 0 18px rgba(255, 51, 153, 0.5), 0 0 35px rgba(255, 51, 153, 0.3)"
        })
        .to(element, {
            textShadow: "0 0 10px rgba(255, 51, 153, 0.9), 0 0 25px rgba(255, 51, 153, 0.7), 0 0 45px rgba(255, 51, 153, 0.5)"
        })
        .to(element, {
            textShadow: "0 0 5px rgba(255, 51, 153, 0.5), 0 0 12px rgba(255, 51, 153, 0.3), 0 0 25px rgba(255, 51, 153, 0.1)"
        });
    });
}
