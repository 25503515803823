import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu przejścia kolorów
const transitionPalettes = {
    default: {
        color1: 'blue',
        color2: 'purple',
        glowColor1: 'rgba(0, 0, 255, 1)', // niebieski
        glowColor2: 'rgba(128, 0, 128, 1)' // fioletowy
    },
    custom: {
        color1: 'orange',
        color2: 'pink',
        glowColor1: 'rgba(255, 165, 0, 1)', // pomarańczowy
        glowColor2: 'rgba(255, 20, 147, 1)' // różowy
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-color-transition`
export function neonColorTransitionEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = transitionPalettes[paletteName] || transitionPalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Ustawienia początkowe
        gsap.set(element, {
            color: palette.color1,
            textShadow: `0 0 10px ${palette.glowColor1}, 0 0 20px ${palette.glowColor1}`,
            willChange: 'transform'
        });

        // Definiowanie animacji przejścia kolorów
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 2 // Czas trwania przejścia kolorów
        });

        tl.to(element, {
            color: palette.color2,
            textShadow: `0 0 10px ${palette.glowColor2}, 0 0 20px ${palette.glowColor2}`
        });

        // Obsługa zdarzeń `mouseenter` i `mouseleave`
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                color: palette.color2,
                textShadow: `0 0 10px ${palette.glowColor2}, 0 0 20px ${palette.glowColor2}`,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                color: palette.color1,
                textShadow: `0 0 10px ${palette.glowColor1}, 0 0 20px ${palette.glowColor1}`,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
