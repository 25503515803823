import { gsap } from "gsap";

// Function to create the neon-shake effect
export function neonShakeEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'transform, text-shadow',
            textShadow: "0 0 10px rgba(255, 0, 0, 0.5)"
        });

        // Define the animation timeline
        const tl = gsap.timeline({ 
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "none", // Linear easing for a consistent shake effect
            duration: 0.5 // Total duration of one shake cycle
        });

        // Define keyframes for the shake animation
        tl.to(element, { 
            x: -2, y: 2, 
            textShadow: "0 0 20px rgba(255, 0, 0, 0.7)" 
        })
        .to(element, { 
            x: 2, y: -2, 
            textShadow: "0 0 30px rgba(255, 0, 0, 1)" 
        })
        .to(element, { 
            x: -2, y: -2, 
            textShadow: "0 0 20px rgba(255, 0, 0, 0.7)" 
        })
        .to(element, { 
            x: 0, y: 0, 
            textShadow: "0 0 10px rgba(255, 0, 0, 0.5)" 
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 30px rgba(255, 0, 0, 1)",
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 10px rgba(255, 0, 0, 0.5)",
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// neonShakeEffect('.neon-shake');
