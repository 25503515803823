import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu "oddychania" neonu
const breathingPalettes = {
    default: {
        baseColor: 'rgba(255, 255, 0, 0.5)', // Kolor początkowy
        glowColor: 'rgba(255, 255, 0, 1)'    // Kolor podczas pełnego świecenia
    },
    custom: {
        baseColor: 'rgba(0, 128, 255, 0.5)',
        glowColor: 'rgba(0, 128, 255, 1)'
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-breathing`
export function neonBreathingEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = breathingPalettes[paletteName] || breathingPalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Ustawienia początkowe
        gsap.set(element, {
            textShadow: `0 0 10px ${palette.baseColor}, 0 0 20px ${palette.baseColor}, 0 0 30px ${palette.baseColor}`,
            willChange: 'transform'
        });

        // Definiowanie animacji "oddychania"
        const tl = gsap.timeline({
            repeat: -1, // Powtarzanie w nieskończoność
            yoyo: true, // Powrót do stanu początkowego
            ease: "power1.inOut",
            duration: 2, // Czas trwania pełnego cyklu
        });

        tl.to(element, {
            textShadow: `0 0 20px ${palette.glowColor}, 0 0 30px ${palette.glowColor}, 0 0 40px ${palette.glowColor}`
        });

        // Obsługa zdarzeń `mouseenter` i `mouseleave`
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: `0 0 20px ${palette.glowColor}, 0 0 30px ${palette.glowColor}, 0 0 40px ${palette.glowColor}`,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: `0 0 10px ${palette.baseColor}, 0 0 20px ${palette.baseColor}, 0 0 30px ${palette.baseColor}`,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
