import { gsap } from "gsap";

// Function to create the neon-flash effect
export function neonFlashEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'opacity, text-shadow',
            opacity: 1, // Start with full opacity
            textShadow: "0 0 10px rgba(255, 165, 0, 0.5), 0 0 20px rgba(255, 165, 0, 0.5)" // Initial text shadow
        });

        // Define the animation timeline
        const tl = gsap.timeline({
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "none", // No easing for an abrupt flash effect
            duration: 1 // Total duration of one flash cycle
        });

        // Define the keyframes for the flash animation
        tl.to(element, {
            opacity: 0.2, // Flash dimming effect
            textShadow: "none" // Remove text shadow during flash
        })
        .to(element, {
            opacity: 1, // Restore full opacity
            textShadow: "0 0 10px rgba(255, 165, 0, 0.5), 0 0 20px rgba(255, 165, 0, 0.5)" // Restore text shadow
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                opacity: 1, // Brighten on hover
                textShadow: "0 0 20px rgba(255, 165, 0, 1), 0 0 40px rgba(255, 165, 0, 1)", // Intensify shadow on hover
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                opacity: 1, // Restore full opacity after hover
                textShadow: "0 0 10px rgba(255, 165, 0, 0.5), 0 0 20px rgba(255, 165, 0, 0.5)", // Restore shadow after hover
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// neonFlashEffect('.neon-flash');
