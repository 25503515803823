import { gsap } from "gsap";

// Function to create the neon-back-shadow effect
export function neonBackShadowEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#00ffff', // Neon cyan color
            willChange: 'text-shadow'
        });

        // Timeline for the back shadow animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "none",
            duration: 4
        });

        tl.to(element, {
            textShadow: "0 0 10px rgba(0, 255, 255, 1), 0 0 20px rgba(0, 255, 255, 0.8), 0 0 30px rgba(0, 255, 255, 0.6), -10px -10px 30px rgba(0, 255, 255, 0.4)"
        });
    });
}
