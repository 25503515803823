import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu statycznego neonu
const staticPalettes = {
    default: {
        baseColor: 'rgba(255, 85, 255, 1)', // Kolor podstawowy
        glowColor1: 'rgba(255, 85, 255, 0.7)', // Pierwszy cień
        glowColor2: 'rgba(255, 85, 255, 0.7)', // Drugi cień
        glowColor3: 'rgba(255, 85, 255, 0.7)', // Trzeci cień
        glowColor4: 'rgba(255, 85, 255, 0.7)'  // Czwarty cień
    },
    custom: {
        baseColor: 'rgba(0, 255, 0, 1)',
        glowColor1: 'rgba(0, 255, 0, 0.7)',
        glowColor2: 'rgba(0, 255, 0, 0.7)',
        glowColor3: 'rgba(0, 255, 0, 0.7)',
        glowColor4: 'rgba(0, 255, 0, 0.7)'
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-static`
export function neonStaticEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = staticPalettes[paletteName] || staticPalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Ustawienia początkowe
        gsap.set(element, {
            color: palette.baseColor,
            textShadow: `
                0 0 10px ${palette.glowColor1},
                0 0 20px ${palette.glowColor2},
                0 0 30px ${palette.glowColor3},
                0 0 40px ${palette.glowColor4}
            `,
            willChange: 'transform'
        });
    });
}
