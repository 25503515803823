import { gsap } from "gsap";

// Function to create the neon-smooth-transition effect
export function neonSmoothTransitionEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#9933ff', // Neon purple color
            willChange: 'text-shadow'
        });

        // Timeline for the smooth transition animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "ease-in-out",
            duration: 2
        });

        tl.to(element, {
            textShadow: "0 0 15px rgba(153, 51, 255, 1), 0 0 30px rgba(153, 51, 255, 0.8), 0 0 45px rgba(153, 51, 255, 0.6)"
        });
    });
}
