import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu migania neonu
const blinkPalettes = {
    default: {
        baseColor: 'rgba(0, 255, 0, 0.7)', // Kolor początkowy
        blinkColor: 'rgba(0, 255, 0, 1)'   // Kolor podczas migania
    },
    custom: {
        baseColor: 'rgba(255, 0, 0, 0.7)',
        blinkColor: 'rgba(255, 0, 0, 1)'
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-blink`
export function neonBlinkEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = blinkPalettes[paletteName] || blinkPalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        gsap.set(element, {
            textShadow: `0 0 10px ${palette.baseColor}, 0 0 20px ${palette.baseColor}`,
            willChange: 'transform'
        });

        const tl = gsap.timeline({
            repeat: -1, // Powtarzanie w nieskończoność
            ease: "none",
            duration: 1, // Czas trwania migania
        });

        tl.to(element, {
            opacity: 0,
            duration: 0.49, // Czas trwania zanikania
            textShadow: 'none'
        }).to(element, {
            opacity: 1,
            duration: 0.49, // Czas trwania pojawiania się
            textShadow: `0 0 10px ${palette.blinkColor}, 0 0 20px ${palette.blinkColor}`
        });

        // Obsługa zdarzeń `mouseenter` i `mouseleave`
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                opacity: 1,
                textShadow: `0 0 10px ${palette.blinkColor}, 0 0 20px ${palette.blinkColor}`,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                opacity: 1,
                textShadow: `0 0 10px ${palette.baseColor}, 0 0 20px ${palette.baseColor}`,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
