import { gsap } from "gsap";

// Function to create the neon-sparkle effect
export function neonSparkleEffect(selector) {
    const elements = document.querySelectorAll(selector);

    elements.forEach(element => {
        // Initial setup
        gsap.set(element, {
            color: '#ffffff', // Neon white color
            willChange: 'text-shadow'
        });

        // Timeline for the sparkle animation
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "none",
            duration: 1
        });

        tl.to(element, {
            textShadow: "0 0 20px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 0.8)"
        })
        .to(element, {
            textShadow: "0 0 15px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.6)"
        })
        .to(element, {
            textShadow: "0 0 25px rgba(255, 255, 255, 0.9), 0 0 50px rgba(255, 255, 255, 0.7)"
        });
    });
}
