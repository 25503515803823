import { gsap } from "gsap";

// Zdefiniuj paletę kolorów dla efektu wielokolorowego neonowego
const multiPalettes = {
    default: {
        color1: 'red',
        color2: 'yellow',
        color3: 'green',
        glowColor1: 'rgba(255, 0, 0, 1)',
        glowColor2: 'rgba(255, 255, 0, 1)',
        glowColor3: 'rgba(0, 255, 0, 1)'
    },
    custom: {
        color1: '#ff00ff',
        color2: '#00ffff',
        color3: '#ff9900',
        glowColor1: 'rgba(255, 0, 255, 1)',
        glowColor2: 'rgba(0, 255, 255, 1)',
        glowColor3: 'rgba(255, 153, 0, 1)'
    }
};

// Eksportowana funkcja do zastosowania efektu `neon-multi`
export function neonMultiColorEffect(selector, paletteName = 'default') {
    const elements = document.querySelectorAll(selector);
    const palette = multiPalettes[paletteName] || multiPalettes.default;

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Ustawienia początkowe
        gsap.set(element, {
            color: palette.color1,
            textShadow: `0 0 10px ${palette.glowColor1}, 0 0 20px ${palette.glowColor1}, 0 0 30px ${palette.glowColor1}`,
            willChange: 'transform'
        });

        // Definiowanie animacji zmiany kolorów
        const tl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 3, // Czas trwania animacji
        });

        tl.to(element, {
            color: palette.color2,
            textShadow: `0 0 10px ${palette.glowColor2}, 0 0 20px ${palette.glowColor2}, 0 0 30px ${palette.glowColor2}`
        })
        .to(element, {
            color: palette.color3,
            textShadow: `0 0 10px ${palette.glowColor3}, 0 0 20px ${palette.glowColor3}, 0 0 30px ${palette.glowColor3}`
        });

        // Zdarzenia `mouseenter` i `mouseleave` dla interakcji z użytkownikiem
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                color: palette.color3,
                textShadow: `0 0 10px ${palette.glowColor3}, 0 0 20px ${palette.glowColor3}, 0 0 30px ${palette.glowColor3}`,
                ease: "power1.out",
                overwrite: "all"
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                color: palette.color1,
                textShadow: `0 0 10px ${palette.glowColor1}, 0 0 20px ${palette.glowColor1}, 0 0 30px ${palette.glowColor1}`,
                ease: "power1.out",
                overwrite: "all",
                onComplete: () => {
                    tl.resume();
                }
            });
        });
    });
}
