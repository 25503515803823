import { gsap } from "gsap";

// Function to create the neon-expand effect
export function neonExpandEffect(selector) {
    const elements = document.querySelectorAll(selector);

    if (elements.length === 0) {
        console.warn('No elements with the specified selector found.');
        return;
    }

    elements.forEach(element => {
        // Initial setup for the element
        gsap.set(element, {
            willChange: 'transform, text-shadow, letter-spacing',
            textShadow: "0 0 10px rgba(128, 0, 128, 0.5)",
            letterSpacing: "normal"
        });

        // Define the animation timeline
        const tl = gsap.timeline({
            repeat: -1,  // Infinite repeat
            yoyo: true,  // Reverses animation every cycle
            ease: "power1.inOut", // Smooth easing for the expand effect
            duration: 2 // Total duration of one expand cycle
        });

        // Define the keyframes for the expand animation
        tl.to(element, {
            textShadow: "0 0 30px rgba(128, 0, 128, 1)", // Stronger shadow glow
            letterSpacing: "2px" // Expanded letter spacing
        });

        // Handle mouseenter and mouseleave events
        element.addEventListener('mouseenter', () => {
            tl.pause();
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 30px rgba(128, 0, 128, 1)",
                letterSpacing: "2px",
                overwrite: true
            });
        });

        element.addEventListener('mouseleave', () => {
            gsap.to(element, {
                duration: 0.2,
                textShadow: "0 0 10px rgba(128, 0, 128, 0.5)",
                letterSpacing: "normal",
                overwrite: true,
                onComplete: () => tl.resume()
            });
        });
    });
}

// Example usage:
// neonExpandEffect('.neon-expand');
